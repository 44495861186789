import { views } from "@xbs/filemanager";

// copy of constants from fm
const FOLDER = 0x01; // folders (any folders in tree and right side)
const TEXT = 0x02; // code type files
const VIEW = 0x04; // files that can be opened in a browser (some audio files are downloadable)
const COMPACT = 0x08; // compact mode
const SEARCH = 0x10; // menu opened in search (search results)
const FILE = 0x20; // files
const SINGLE = 0x40; // menu is opened for 1 item (file or folder)
const RIGHT = 0x80; // menu is opened on the right side (item or empty space)
const ITEMS = 0x100; // files and folders in both parts

const STAR = 0x200;
const TRASH = 0x7f; // 1111111 (!sic) this was done to make these options invisible for that '1-bit' filtering

export default class MenuBodyView extends views["menus/menubody"] {
	constructor(app, config) {
		super(app, config);
		this.state = config.state;
	}
	config() {
		const _ = this.app.getService("locale")._;
		const menu = super.config();

		const options = [
			{
				id: "favorite",
				show: FILE | SINGLE | STAR,
				value: _("Add to favorites"),
				icon: "dmi-star-outline",
			},
			{
				id: "unfavorite",
				show: FILE | SINGLE | STAR,
				value: _("Remove from favorites"),
				icon: "dmi-star",
			},
			{
				id: "share",
				value: _("Share"),
				show: FILE | SINGLE,
				icon: "dmi-share",
			},
			{
				$template: "Separator",
				show: FILE | SINGLE,
			},
		];

		menu.data.splice(11, 0, ...options);

		menu.data = menu.data.concat([
			{
				id: "restore",
				value: _("Restore"),
				show: TRASH,
				icon: "dmi-delete-restore",
			},
			{
				id: "remove",
				value: _("Delete from trash"),
				show: TRASH,
				icon: "dmi-delete-empty",
				hotkey: "Del / &#8592;",
			},
		]);

		menu.template = obj => {
			return (
				(obj.icon
					? `<span class="webix_list_icon webix_icon ${obj.icon}"></span>`
					: "") +
				obj.value +
				(obj.hotkey
					? `<span class="webix_fmanager_context-menu-hotkey">${obj.hotkey}</span>`
					: "")
			);
		};

		return menu;
	}
	FilterOptions(files) {
		const source = this.state ? this.state.source : null;
		const menu = this.getRoot();
		this.Files = files;

		if (source !== "trash") {
			// here we fully copy and slightly change super.FilterOptions
			const file = files[0];
			const many = files.length > 1;

			const vtypes = ["image", "audio", "video", "code", "pdf"];
			const viewable = vtypes.find(t => t === file.type || t === file.$ext);
			const editable =
				file.type === "code" || (file.$ext === "xls" || file.$ext === "xlsx");
			const search = this.app.getState().search;
			const empty = file.type === "empty";

			this.getRoot().define("width", search && !many ? 270 : 200);

			this.getRoot().filter(o => {
				return !(
					(o.show & SINGLE && many) ||
					(o.show & FOLDER && file.type !== "folder") ||
					(o.show & FILE && (file.type === "folder" || file.type == "empty")) ||
					(o.show & TEXT && !editable) ||
					(o.show & VIEW && !viewable) ||
					(o.show & COMPACT && !this.compact) ||
					(o.show & RIGHT && this.tree) ||
					(o.show & ITEMS && empty) ||
					(o.show & SEARCH && !search) ||
					(o.show & STAR && file.star
						? o.id === "favorite"
						: o.id === "unfavorite")
				);
			});

			// filter out copy-cut-paste section for group folders
			this.FilterCopyPaste(source, menu);
		} else {
			menu.define("width", 250);
			menu.filter(o => o.id === "remove" || o.id === "restore");
		}
	}
	FilterCopyPaste(source, menu) {
		if (source !== "files") {
			menu.filter(
				o => {
					return o.show ^ (RIGHT | ITEMS) && o.show ^ RIGHT;
				},
				null,
				true
			);
		}
	}
}
